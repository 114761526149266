// src/components/About.js
import React from 'react';

const About = () => {
  return (
    <div className='text-white my-5 p-2'>
      <h1 className='text-center pb-4'>About US</h1>
      <p>
      Welcome to Calculadora.org.es, your go-to destination for reliable and easy-to-use
calculator tools! </p> 
<p>
At Calculadora.org.es, we understand the importance of precision and simplicity in
everyday calculations. Whether you're a student tackling complex math problems, a
professional needing quick computations, or someone managing personal finances, our
versatile calculator tool is designed to meet your needs efficiently.</p> 

 <h2 className='pt-3'>Our Mission</h2>
 <p>
Our mission is to provide users with a seamless and intuitive online calculator
experience. We aim to make calculations straightforward, eliminating the hassle of
manual computation and reducing the risk of errors. Our tool is built to offer accurate
results swiftly, helping you save time and effort.</p>

<h2 className='pt-3'>Why Choose Us?</h2>
<ul>
<li> User-Friendly Interface: Our calculator is designed with a clean and intuitive
interface, ensuring that anyone can use it with ease.</li>
<li>Accuracy: We prioritize precision in every calculation, so you can trust the results
our tool provides.</li>
<li>Versatility: Whether you need a simple arithmetic calculation or a more complex
mathematical function, our calculator has you covered.</li>
<li>Accessibility: Available online anytime, anywhere, our calculator is here for you
whenever you need it.</li>
<li>Free to Use: Enjoy all the features of our calculator without any cost.</li>
</ul>


<h2 className='pt-3'>
Our Vision
</h2>
<p>
We envision becoming a trusted companion for anyone in need of reliable and quick
calculations. By continuously improving our tool and expanding its functionalities, we
strive to cater to a broad audience, from students and educators to professionals and
homemakers.
</p> 
<p>
Thank you for choosing Calculadora.org.es. We are committed to making your
calculation tasks easier and more efficient. Explore our calculator tool today and
experience the difference!
</p> 
<p>
For any inquiries or feedback, please feel free to contact us. Your satisfaction is our
priority.
            </p>
    </div>
  );
};

export default About;
