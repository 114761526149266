// calculator.js

import { evaluate } from 'mathjs';

// Function to evaluate the expression
const evaluateExpression = (expression) => {
    try {
        // Use mathjs to safely evaluate the expression
        const result = evaluate(expression);

        return result;
    } catch (error) {
        // If there's an error in evaluation, return NaN
        return NaN;
    }
};

export default evaluateExpression;
