import React, { useState } from 'react';
import evaluateExpression from './calculadora';


const Calculator = () => {
  const [expression, setExpression] = useState(''); // State to hold the expression entered by the user

  const [activeKeypad, setActiveKeypad] = useState('first'); // State to track which keypad is currently active


  // Function to handle button clicks
  const handleButtonClick = (value) => {
    setExpression((prevExpression) => prevExpression + value); // Append the clicked button value to the expression
  };

  // Function to clear the input
  const clearInput = () => {
    setExpression('');
  };

  // Function to calculate the result
  const calculateResult = () => {
    const calculatedResult = evaluateExpression(expression); // Call the evaluateExpression function
    setExpression(calculatedResult.toString()); // Update the expression state with the calculated result
  };

   // Function to toggle between the keypads
   const toggleKeypad = () => {
    setActiveKeypad(activeKeypad === 'first' ? 'second' : 'first');
  };

  return (
    <div className="calculator" id="input-buttons">
      {/* Display FOR Desktop Screen */}
        <div className='div1'>
      <div className="display">
        {/* Show the result directly in the input field */}
        <input id="input" type="text" value={expression} readOnly />
      </div>
                <div className="kp-row">
                  <div className="kp-calculadora-button" onClick={() => handleButtonClick('sin(')}>sin</div>
                  <div className="kp-calculadora-button kp-4letters" onClick={() => handleButtonClick('asin(')}>asin</div>
                  <div className="kp-calculadora-button" onClick={() => handleButtonClick('lg(')}>log</div>
                  <div className="kp-calculadora-button kp-5letters" onClick={() => handleButtonClick('floor(')}>floor</div>
                  <div className="kp-calculadora-button kp-5letters" onClick={() => handleButtonClick('round(')}>round</div>
                  <div className="kp-calculadora-button"onClick={() => handleButtonClick('%')}>%</div>
                  <div className="kp-calculadora-button kp-num kp-2" onClick={() => handleButtonClick('a')}>a</div>
                  <div className="kp-calculadora-button kp-num kp-2" onClick={() => handleButtonClick('b')}>b</div>
                  <div className="kp-calculadora-button kp-num kp-2" onClick={() => handleButtonClick('c')}>c</div>
                  <div className="kp-calculadora-button" onClick={() => handleButtonClick('sqrt(')}>√x</div>
                  <div className="kp-calculadora-button kp-num" onClick={() => handleButtonClick('7')}>7</div>
                  <div className="kp-calculadora-button kp-num" onClick={() => handleButtonClick('8')}>8</div>
                  <div className="kp-calculadora-button kp-num" onClick={() => handleButtonClick('9')}>9</div>
                  <div className="kp-calculadora-button" onClick={() => handleButtonClick('+')}>+</div>
                  <div className="kp-calculadora-button kp-back" id="clear-keypad-button"  onClick={clearInput}>&#129184;</div>
                </div>
                <div className="kp-row">
                  <div className="kp-calculadora-button" onClick={() => handleButtonClick('cos(')}>cos</div>
                  <div className="kp-calculadora-button kp-4letters" onClick={() => handleButtonClick('acos(')}>acos</div>
                  <div className="kp-calculadora-button" onClick={() => handleButtonClick('ln(')}>ln</div>
                  <div className="kp-calculadora-button kp-4letters" onClick={() => handleButtonClick('ceil(')}>ceil</div>
                  <div className="kp-calculadora-button" onClick={() => handleButtonClick('exp(')}>exp</div>
                  <div className="kp-calculadora-button" onClick={() => handleButtonClick('!')}>x!</div>
                  <div className="kp-calculadora-button kp-num kp-2" onClick={() => handleButtonClick('d')}>d</div>
                  <div className="kp-calculadora-button kp-num kp-2" onClick={() => handleButtonClick('e')}>e</div>
                  <div className="kp-calculadora-button kp-num kp-2" onClick={() => handleButtonClick('f')}>f</div>
                  <div className="kp-calculadora-button" onClick={() => handleButtonClick('^')}>x<sup style={{ fontSize: '10px' }}>y</sup></div>
                  <div className="kp-calculadora-button kp-num" onClick={() => handleButtonClick('4')}>4</div>
                  <div className="kp-calculadora-button kp-num" onClick={() => handleButtonClick('5')}>5</div>
                  <div className="kp-calculadora-button kp-num" onClick={() => handleButtonClick('6')}>6</div>
                  <div className="kp-calculadora-button" onClick={() => handleButtonClick('-')}>-</div>
                  <div className="kp-calculadora-button" onClick={() => handleButtonClick('(')}>(</div>
                </div>
                <div className="kp-row">
                  <div className="kp-calculadora-button" onClick={() => handleButtonClick('tan(')}>tan</div>
                  <div className="kp-calculadora-button kp-4letters" onClick={() => handleButtonClick('atan(')}>atan</div>
                  <div className="kp-calculadora-button" onClick={() => handleButtonClick('lg(')}>lg</div>
                  <div className="kp-calculadora-button" onClick={() => handleButtonClick('max(')}>max</div>
                  <div className="kp-calculadora-button" onClick={() => handleButtonClick('pow(')}>pow</div>
                  <div className="kp-calculadora-button" onClick={() => handleButtonClick(',')}>,</div>
                  <div className="kp-calculadora-button" onClick={() => handleButtonClick('bin(')}>bin</div>
                  <div className="kp-calculadora-button" onClick={() => handleButtonClick('dec(')}>dec</div>
                  <div className="kp-calculadora-button kp-4letters" onClick={() => handleButtonClick('base(')}>base</div>
                  <div className="kp-calculadora-button" onClick={() => handleButtonClick('mod(')}>mod</div>
                  <div className="kp-calculadora-button kp-num" onClick={() => handleButtonClick('1')}>1</div>
                  <div className="kp-calculadora-button kp-num" onClick={() => handleButtonClick('2')}>2</div>
                  <div className="kp-calculadora-button kp-num" onClick={() => handleButtonClick('3')}>3</div>
                  <div className="kp-calculadora-button" onClick={() => handleButtonClick('*')}>×</div>
                  <div className="kp-calculadora-button" onClick={() => handleButtonClick(')')}>)</div>
                </div>
                <div className="kp-row">
                  <div className="kp-calculadora-button" onClick={() => handleButtonClick('#')}>deg</div>
                  <div className="kp-calculadora-button" onClick={() => handleButtonClick('pi')}>π</div>
                  <div className="kp-calculadora-button">e</div>
                  <div className="kp-calculadora-button" onClick={() => handleButtonClick('min(')}>min</div>
                  <div className="kp-calculadora-button kp-4letters" onClick={() => handleButtonClick('random()')}>rndm</div>
                  <div className="kp-calculadora-button" onClick={() => handleButtonClick('42')}>?</div>
                  <div className="kp-calculadora-button" onClick={() => handleButtonClick('^2')}>x<sup style={{ fontSize: '10px' }}>2</sup></div>
                  <div className="kp-calculadora-button" onClick={() => handleButtonClick('^3')}>x<sup style={{ fontSize: '10px' }}>3</sup></div>
                  <div className="kp-calculadora-button" onClick={() => handleButtonClick('10^')}>10<sup style={{ fontSize: '10px' }}>x</sup></div>
                  <div className="kp-calculadora-button" onClick={() => handleButtonClick('abs(')}>|x|</div>
                  <div className="kp-calculadora-button kp-num" onClick={() => handleButtonClick('00')}>00</div>
                  <div className="kp-calculadora-button kp-num" onClick={() => handleButtonClick('0')}>0</div>
                  <div className="kp-calculadora-button kp-num" onClick={() => handleButtonClick('.')}>.</div>
                  <div className="kp-calculadora-button kp-num" onClick={() => handleButtonClick('/')}>÷</div>
                  <div className="kp-calculadora-button kp-eql" onClick={calculateResult}>=</div>
                </div>
            
                </div>
      {/* Display FOR Desktop Screen */}
     
      <div className="panel div2">
      <div className="display">
        <input id="input" type="text" value={expression} readOnly />
      </div>
      <div className="text-center">
       {/* Toggle keypad SVG icon */}
       <div className="my-2" onClick={toggleKeypad}>
            {activeKeypad === 'first' ? (
              <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.5474 19.3987L20.1221 25.7861C21.3953 26.8594 23.3333 25.9495 23.3333 24.2782V10.7217C23.3333 9.05046 21.3938 8.14056 20.1221 9.21389L12.5474 15.6012C11.3735 16.5914 11.3735 18.4085 12.5474 19.3987Z" fill="white"/>
              </svg>
            ) : (
              <svg width="13" height="19" viewBox="0 0 13 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.4526 11.3987L3.87786 17.7861C2.60473 18.8594 0.666748 17.9495 0.666748 16.2782V2.72171C0.666748 1.05046 2.60619 0.140558 3.87786 1.21389L11.4526 7.60122C12.6265 8.59143 12.6265 10.4085 11.4526 11.3987Z" fill="white"/>
              </svg>
            )}
          </div>
      </div>
      <div id="first_keypad" style={{ display: activeKeypad === 'first' ? 'block' : 'none' }}>
        <div className="kp-calculadora-button" onClick={() => handleButtonClick('#')}>deg</div>
        <div className="kp-calculadora-button" onClick={() => handleButtonClick('!')}>x!</div>
        <div className="kp-calculadora-button" onClick={() => handleButtonClick('42')}>?</div>
        <div className="kp-calculadora-button" onClick={() => handleButtonClick(',')}>,</div>
        <div className="kp-calculadora-button" onClick={() => handleButtonClick('(')}>(</div>
        <div className="kp-calculadora-button" onClick={() => handleButtonClick(')')}>)</div>
        <div className="kp-calculadora-button" onClick={() => handleButtonClick('^2')}>x<sup style={{ fontSize: '10px' }}>2</sup></div>
        <div className="kp-calculadora-button" onClick={() => handleButtonClick('^3')}>x<sup style={{ fontSize: '10px' }}>3</sup></div>
        <div className="kp-calculadora-button" onClick={() => handleButtonClick('sqrt(')}>√x</div>
        <div className="kp-calculadora-button kp-back" onClick={clearInput}>🢠</div>
        <div className="kp-calculadora-button" onClick={() => handleButtonClick('%')}>%</div>
        <div className="kp-calculadora-button" onClick={() => handleButtonClick('+')}>+</div>
        <div className="kp-calculadora-button kp-num" onClick={() => handleButtonClick('7')}>7</div>
        <div className="kp-calculadora-button kp-num" onClick={() => handleButtonClick('8')}>8</div>
        <div className="kp-calculadora-button kp-num" onClick={() => handleButtonClick('9')}>9</div>
        <div className="kp-calculadora-button" onClick={() => handleButtonClick('-')}>-</div>
        <div className="kp-calculadora-button kp-num" onClick={() => handleButtonClick('4')}>4</div>
        <div className="kp-calculadora-button kp-num" onClick={() => handleButtonClick('5')}>5</div>
        <div className="kp-calculadora-button kp-num" onClick={() => handleButtonClick('6')}>6</div>
        <div className="kp-calculadora-button" onClick={() => handleButtonClick('*')}>×</div>
        <div className="kp-calculadora-button kp-num" onClick={() => handleButtonClick('1')}>1</div>
        <div className="kp-calculadora-button kp-num" onClick={() => handleButtonClick('2')}>2</div>
        <div className="kp-calculadora-button kp-num" onClick={() => handleButtonClick('3')}>3</div>
        <div className="kp-calculadora-button" onClick={() => handleButtonClick('/')}>÷</div>
        <div className="kp-calculadora-button kp-num" onClick={() => handleButtonClick('00')}>00</div>
        <div className="kp-calculadora-button kp-num" onClick={() => handleButtonClick('0')}>0</div>
        <div className="kp-calculadora-button kp-num" onClick={() => handleButtonClick('.')}>.</div>
        <div className="kp-calculadora-button kp-eql" onClick={calculateResult}>=</div>
      </div>
      <div id="second_keypad" style={{ display: activeKeypad === 'second' ? 'block' : 'none' }}>
        <div className="kp-calculadora-button" onClick={() => handleButtonClick('pi')}>π</div>
        <div className="kp-calculadora-button kp-num kp-2" onClick={() => handleButtonClick('e')}>e</div>
        <div className="kp-calculadora-button" onClick={() => handleButtonClick('lg(')}>lg</div>
        <div className="kp-calculadora-button" onClick={() => handleButtonClick('ln(')}>ln</div>
        <div className="kp-calculadora-button" onClick={() => handleButtonClick('abs(')}>|x|</div>
        <div className="kp-calculadora-button" onClick={() => handleButtonClick('^')}>x<sup style={{ fontSize: '10px' }}>y</sup></div>
        <div className="kp-calculadora-button" onClick={() => handleButtonClick('10^')}>10<sup style={{ fontSize: '10px' }}>x</sup></div>
        <div className="kp-calculadora-button kp-num kp-2" onClick={() => handleButtonClick('a')}>a</div>
        <div className="kp-calculadora-button kp-num kp-2" onClick={() => handleButtonClick('b')}>b</div>
        <div className="kp-calculadora-button kp-num kp-2" onClick={() => handleButtonClick('c')}>c</div>
        <div className="kp-calculadora-button kp-num kp-2" onClick={() => handleButtonClick('d')}>d</div>
        <div className="kp-calculadora-button kp-num kp-2" onClick={() => handleButtonClick('e')}>e</div>
        <div className="kp-calculadora-button kp-num kp-2" onClick={() => handleButtonClick('f')}>f</div>
        <div className="kp-calculadora-button" onClick={() => handleButtonClick('sin(')}>sin</div>
        <div className="kp-calculadora-button kp-4letters" onClick={() => handleButtonClick('asin(')}>asin</div>
        <div className="kp-calculadora-button" onClick={() => handleButtonClick('lg(')}>log</div>
        <div className="kp-calculadora-button" onClick={() => handleButtonClick('tan(')}>tan</div>
        <div className="kp-calculadora-button kp-4letters" onClick={() => handleButtonClick('atan(')}>atan</div>
        <div className="kp-calculadora-button" onClick={() => handleButtonClick('max(')}>max</div>
        <div className="kp-calculadora-button" onClick={() => handleButtonClick('cos(')}>cos</div>
        <div className="kp-calculadora-button kp-4letters" onClick={() => handleButtonClick('acos(')}>acos</div>
        <div className="kp-calculadora-button" onClick={() => handleButtonClick('pow(')}>pow</div>
        <div className="kp-calculadora-button kp-4letters" onClick={() => handleButtonClick('random()')}>rndm</div>
        <div className="kp-calculadora-button" onClick={() => handleButtonClick('min(')}>min</div>
        <div className="kp-calculadora-button kp-4letters" onClick={() => handleButtonClick('base(')}>base</div>
        <div className="kp-calculadora-button" onClick={() => handleButtonClick('dec(')}>dec</div>
        <div className="kp-calculadora-button kp-5letters" onClick={() => handleButtonClick('round(')}>round</div>
        <div className="kp-calculadora-button" onClick={() => handleButtonClick('exp(')}>exp</div>
        <div className="kp-calculadora-button" onClick={() => handleButtonClick('mod(')}>mod</div>
        <div className="kp-calculadora-button kp-5letters" onClick={() => handleButtonClick('floor(')}>floor</div>
        <div className="kp-calculadora-button kp-4letters" onClick={() => handleButtonClick('ceil(')}>ceil</div>
        <div className="kp-calculadora-button kp-back" onClick={clearInput}>🢠</div>
      </div>
              
              </div>
      
    </div>

   
  );
};

export default Calculator;
