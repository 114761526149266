import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link, Navigate } from 'react-router-dom';
import About from './About';
import Home from './Home';
import Contact from './Contact';
import Privacy from './Privacy';
import 'bootstrap/dist/css/bootstrap.min.css';
import './calculadora.css';
import logo from './logo.jpg'; // Make sure to import your logo correctly
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

function App() {
  return (
    <Router>
      <div className="App">
        <header className="navbar-theme-color fixed-top">
          <div className="container">
            <nav className="navbar navbar-expand-lg">
              <div className="container-fluid">
                <Link className="navbar-brand" to="/">
                  <img width="100" height="80" src={logo} alt="Logo" />
                </Link>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                  <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse justify-content-center" id="navbarNav">
                  <ul className="navbar-nav">
                    <li className="nav-item">
                      <Link className="nav-link active" aria-current="page" to="/">Inicio</Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/acerca-de">Acerca de</Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/contacto">Contacto</Link>
                    </li> <li className="nav-item">
                      <Link className="nav-link" to="/política-de-privacidad">Política de Privacidad</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </nav>
          </div>
        </header>
        <main>
          <div className="container mt-5">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/acerca-de" element={<About />} />
              <Route path="/contacto" element={<Contact />} />
             
              <Route path="/política-de-privacidad" element={<Privacy />} />
              {/* Handle 404 - Redirect to Home */}
             <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          </div>
        </main>
      </div>
    </Router>
  );
}

export default App;
