import React from 'react';
import Calculator from './Calculator'; 
import './calculadora.css';


const Home = () => {
  return (
    <div>
      <div className="container">
        <div className="shadow-sm p-5 my-3 bg-body rounded"><p className="text-dark text-center mx-auto">Ads</p></div>
      </div>
      <div className="container my-5">
        <div className="row">
          <div className="col-md-11 col-sm-8 col-xs-8">
            {/* Render the Calculator component */}
            <Calculator />
          </div>
          <div className="col-md-1 col-sm-12 div1">
            <div className="shadow-sm py-5 my-3 bg-body rounded"><p className="text-dark text-center">Ads</p></div>
          </div>
        </div>
      </div>

      <div className="container-calcu-online my-5">
        <h3 className="text-center mb-5 text-white">Calculadora.org.es</h3>
        <div className="row">
          <div className="col-md-4 col-sm-12">
            <div className="box-funtion-3 rounded">
              <p className="text-white">Funciones logarítmicas </p>
              <p>&#129106; log(<span className="text-white">x</span>) = logaritmo de <span className="text-white">x</span> </p>
              <p>&#129106; ln(x) = el logaritmo natural de <span className="text-white">x</span></p>
            </div>
          </div>
          <div className="col-md-4 col-sm-12">
            <div className="box-funtion-3 rounded">
              <p className="text-white">Constantes </p>
              <p>&#129106; π(<span className="text-white">La constante pi</span>) <span className="text-white">=</span>3,141..</p>
              <p>&#129106; e(<span className="text-white">por Napier</span>)<span className="text-white">=</span>2,718..</p>
            </div>
          </div>
          <div className="col-md-4 col-sm-12">
            <div className="box-funtion-3 rounded">
              <p className="text-white">Funciones trigonométricas</p>
              <p><span className="text-white">&#129106; </span>sin(<span className="text-white">x</span>) <span className="text-white">=</span> el seno de <span className="text-white">x</span> </p>
              <p><span className="text-white">&#129106; </span>tan(<span className="text-white">x</span>) <span className="text-white">=</span> la tangente de <span className="text-white">x</span> </p>
            </div>
          </div>
        </div>
      </div>

      <div className="container my-5">
        <div className="row">
          <div className="col-md-12 formula_para">
            <p> Asistencia en los cálculos</p>
            <p> Descripción de las funciones de la calculadora:</p>
            <p> sin() - Seno : función trigonométrico.</p>
            <p> cos() - Coseno : función trigonométrico.</p>
            <p> tan() - Tangente : función trigonométrico.</p>
            <p> deg() - Grado de ángulo : En geometría, un grado es una unidad de medida del ángulo.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
