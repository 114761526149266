import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import './stylecontact.css';

const Contact = () => {
  const [formData, setFormData] = useState({
    fullname: '',
    email: '',
    subject: '',
    message: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  
    emailjs.send(
      'service_mj2j2sh', // replace with your EmailJS service ID
      'template_rz0ixlk', // replace with your EmailJS template ID
      formData,
      'PmvV_Cx7nFKyMimsx' // replace with your EmailJS user ID
    ).then((response) => {
      console.log('SUCCESS!', response.status, response.text);
      alert('Email sent successfully');
      setFormData({
        fullname: '',
        email: '',
        subject: '',
        message: ''
      });
    }).catch((err) => {
      console.log('FAILED...', err);
      alert('Error sending email');
    });
  };
  

  return (
    <div className='padding200 my-5'>
      <div className="container">
        <div className="form">
          <div className="contact-info">
            <h3 className="title">Let's get in touch</h3>
            <p className="text">
              We'd love to hear from you! Whether you have questions, feedback, or need assistance, our team is here to help.
            </p>
            <p className="text">
              Please fill out the contact form, and we'll get back to you as soon as possible.
            </p>
            <p className="text">
              Your satisfaction is our priority at Calculadora.org.es. Thank you for reaching out!
            </p>
          </div>
          <div className="contact-form">
            <span className="circle one"></span>
            <span className="circle two"></span>
            <form onSubmit={handleSubmit} autoComplete="off">
              <h3 className="title">Contact us</h3>
              <div className="input-container">
                <input
                  type="text"
                  name="fullname"
                  className="input"
                  value={formData.fullname}
                  onChange={handleChange}
                />
                <label htmlFor="fullname">Full Name</label>
                <span>Full Name</span>
              </div>
              <div className="input-container">
                <input
                  type="email"
                  name="email"
                  className="input"
                  value={formData.email}
                  onChange={handleChange}
                />
                <label htmlFor="email">Email</label>
                <span>Email</span>
              </div>
              <div className="input-container">
                <input
                  type="text"
                  name="subject"
                  className="input"
                  value={formData.subject}
                  onChange={handleChange}
                />
                <label htmlFor="subject">Subject</label>
                <span>Subject</span>
              </div>
              <div className="input-container textarea">
                <textarea
                  name="message"
                  className="input"
                  value={formData.message}
                  onChange={handleChange}
                ></textarea>
                <label htmlFor="message">Message</label>
                <span>Message</span>
              </div>
              <input type="submit" value="Send" className="btn-contact" />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
