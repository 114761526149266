// src/components/About.js
import React from 'react';

const Privacy = () => {
  return (
    <div className='text-white my-5 p-2'>

      <h1>Privacy Policy</h1>
     <p> At <b>Calculadora.org.es</b>, your privacy is important to us. This Privacy Policy outlines how
we collect, use, and protect your information when you use our website and calculator
tool.</p>
<h2 className='pt-3'>Information We Collect</h2>
<ul>
<li> Personal Information: We may collect personal information, such as your name
and email address, if you choose to contact us or subscribe to our services.</li>
<li> Usage Data: We automatically collect data on how you use our website, including
your IP address, browser type, and pages visited.</li>
</ul>


<h2 className='pt-5'>How We Use Your Information</h2>

<ul>
<li>To Improve Our Services: We use the information to enhance and personalize
your experience on our website.</li>
<li> To Communicate with You: We may use your contact information to respond to
your inquiries or send updates about our services.</li>
</ul>
<h2 className='pt-5'>Information Sharing</h2>
We do not sell, trade, or otherwise transfer your personal information to outside parties.
This does not include trusted third parties who assist us in operating our website,
conducting our business, or servicing you, as long as those parties agree to keep this
information confidential.
<h2 className='pt-5'>Data Security</h2>

We implement various security measures to maintain the safety of your personal
information. However, please note that no method of transmission over the internet, or
method of electronic storage, is 100% secure.
<h2 className='pt-5'>Cookies</h2>
Our website may use cookies to enhance your browsing experience. You can choose to
disable cookies through your browser settings, but this may affect the functionality of
our site.
<h2 className='pt-5'>Third-Party Links</h2>
Our website may contain links to third-party sites. We are not responsible for the privacy
practices or content of these external sites.
<b>Calculadora.org.es</b> - Your Privacy Matters
    </div>
  );
};

export default Privacy;
